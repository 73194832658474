import react, { useEffect, useState, useCallback } from "react";
import { Fragment } from "react";
import Image from "next/legacy/image";
import { HeroIcon } from "../../lib/HeroIcon";
import { absoluteUrl, PropertyExists } from "../../lib/_helper";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import NavigationPanel from "./NavigationPanel";
import NavigationDirectLink from "./NavigationDirectLink";
import { data } from "autoprefixer";
import { mergeClasses } from "../../lib/_helper";
import MobileSearch from "../../lib/Search/MobileSearch";
import Collapsible from "react-collapsible";
import NavigationMobileSection from "./NavigationMobileSection";
import MainLogo from "./navComponents/mainLogo";
import SearchAndContactUs from "./navComponents/searchAndContactUs";

const Navigation = ({ blok }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [isHoveringNavBar, setIsHoveringNavBar] = useState(false);
  const [mobileNavSectionFocus, setMobileNavSectionFocus] = useState(null);
  const [bestMatchingActivePageIndex, setBestMatchingActivePageIndex] = useState(null);

  const padWithSlashes = (value) => {
    let newValue = value;
    if (value != undefined) {
      if (!newValue.startsWith("/")) {
        newValue = "/" + value;
      }

      if (!newValue.endsWith("/")) {
        newValue = value + "/";
      }
    }
    return newValue ?? "/";
  };

  const windowLocationPathname = typeof window === `undefined` ? null : window.location.pathname;
  useEffect(() => {
    let _bestMatchingActivePageIndex = null;
    let bestMatchingActivePagePrefixLength = 0;

    const currentPath = padWithSlashes(windowLocationPathname);
    blok.panels.forEach((panel, index) => {
      const panelPath = padWithSlashes(panel.buttonUrl.story.url);
      const panelPathLength = panelPath.length
      if (currentPath.startsWith(panelPath) && panelPathLength > bestMatchingActivePagePrefixLength) {
        _bestMatchingActivePageIndex = index;
        bestMatchingActivePagePrefixLength = panelPathLength
      }
    });

    setBestMatchingActivePageIndex(_bestMatchingActivePageIndex)
  }, [blok?.panels, windowLocationPathname]);

  return (
    <Popover
      className="relative bg-cto_secondary_navy_blue"
      onMouseEnter={() => setIsHoveringNavBar(true)}
      onMouseLeave={() => setIsHoveringNavBar(false)}
    >
      <div
        className="absolute inset-0 z-30 pointer-events-none"
        aria-hidden="true"
      />
      <div
        className="relative z-20 px-12 py-3"
        onClick={() => setIsShowing(!isShowing)}
      >
        <div
          className="mx-auto flex justify-between items-center py-4 md:space-x-10"
          style={{ minHeight: "90px" }}
        >
          <div className="w-72 flex">
            <MainLogo/>
          </div>
          <div className="lg:hidden -mr-2 -my-2">
            <Popover.Button
              className={mergeClasses(
                "bg-white rounded-md p-2 mx-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              )}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div
            className="hidden lg:flex lg:flex-1" // my-0 mx-4
            style={{ margin: "0 16px" }}
          >
            <div className={mergeClasses("flex items-center w-full justify-center")}>
              <Popover.Group as="nav" className="flex space-x-8 lg:space-x-12">
                {blok.panels.map((panel, index) => {
                  if (panel.component == "navigationPanel") {
                    return (
                      <NavigationPanel
                        key={index}
                        data={panel}
                        isHoveringNavBar={isHoveringNavBar}
                        isActivePage={index == bestMatchingActivePageIndex}
                      />
                    )
                  }
                  else if (panel.component == "navigationDirectLink") {
                    return (
                      <NavigationDirectLink
                        key={index}
                        data={panel}
                        isHoveringNavBar={isHoveringNavBar}
                        isActivePage={index == bestMatchingActivePageIndex}
                      />
                    );
                  }
                })}
              </Popover.Group>
            </div>
          </div>
          <div
            className="hidden lg:flex justify-end w-72 m-0 "
            style={{ marginLeft: 0 }}
          >
            <SearchAndContactUs/>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          {({ close }) => (
            <>
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 overflow-y-scroll h-screen">
                <div className="p-5 flex">
                  <div className="flex items-center justify-start w-full">
                    <Link href={absoluteUrl("/")}>

                      <Image
                        className="h-8 w-auto sm:h-10"
                        src="/cto-dark.svg"
                        alt="cto labs"
                        width={100}
                        height={47.4}
                      />

                    </Link>
                  </div>
                  <div className="items-center">
                    <nav>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="w-full px-5 border-none">
                  <div>
                    <MobileSearch />
                  </div>

                  <div className="py-4">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>

                  <div className="mt-2 sm:mt-4">
                    <nav>
                      <div className="grid gap-7 sm:grid-cols-1 sm:gap-y-8 sm:gap-x-4">
                        {blok.panels.map((panel, index) => {
                          if (panel.component == "navigationPanel") {
                            return (
                              <Collapsible
                              key={index}
                              open={mobileNavSectionFocus == panel.title}
                              trigger={
                                <NavigationMobileSection
                                  panel={panel}
                                  activePanelTitle={mobileNavSectionFocus}
                                />
                              }
                              onTriggerOpening={() =>
                                setMobileNavSectionFocus(panel.title)
                              }
                              onTriggerClosing={() => {
                                if (mobileNavSectionFocus == panel.title)
                                setMobileNavSectionFocus(null);
                              }}
                              >
                                {panel.links.map((link, index) => (
                                  link.hideFromNavigation ||
                                  <div key={index}>
                                    <Link
                                      href={absoluteUrl(
                                        link?.url?.story?.url || "#"
                                        )}
                                      className="m-3 p-3 flex rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                                      onClick={() => close()}>

                                      {PropertyExists(link?.icon) && (
                                        <span
                                        className="flex-shrink-0 h-6 w-6"
                                        aria-hidden="true"
                                        >
                                          <HeroIcon
                                            icon={link.icon}
                                            color="text-text-gray-700"
                                            size={6}
                                            outline
                                            />
                                        </span>
                                      )}
                                      <div className="flex-col">
                                        <div className="ml-4 text-gray-700">
                                          {link.linkText || "#"}
                                        </div>
                                        <div className="ml-4 text-gray-400">
                                          {link.linkDescription || "#"}
                                        </div>
                                      </div>

                                    </Link>
                                  </div>
                                ))}
                                <Link
                                  href={absoluteUrl(
                                    panel?.buttonUrl?.story?.url || "#"
                                    )}
                                  className="ml-8 p-4 col-span-2 w-fit inline-flex items-center justify-center border border-transparent rounded-full shadow-sm text-base font-normal text-white bg-cto_primary_orange hover:bg-cto_primary_purple"
                                  onClick={() => close()}>

                                  {panel.buttonText || "#"}

                                </Link>
                              </Collapsible>
                            );
                          }
                          else if (panel.component == "navigationDirectLink") {
                            return (
                              <div key={index}>
                                <Link href={absoluteUrl(panel.buttonUrl.story.url)}>

                                  <NavigationMobileSection panel={panel} hideArrows={true}/>

                                </Link>
                              </div>
                            );
                          }
                          })}
                      </div>
                    </nav>
                  </div>

                  <div className="py-4">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                </div>

                <div className="pt-2 pb-6 px-5 border-none">
                  <Link
                    href={absoluteUrl("/contact")}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-cto_primary_orange hover:bg-cto_primary_purple">
                    
                      contact us
                    
                  </Link>
                </div>
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Navigation;
