import { sbEditable } from "@storyblok/storyblok-editable";
import Link from "next/link";
import { absoluteUrl } from "../../lib/_helper";
import NavCard from "./NavCard";
import ArticleCard from "../blog-sections/ArticleCard";

const NavigationSidePanel = ({ blok, close }) => {
  return (
    <div
      className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12 col-span-2"
      {...sbEditable(blok)}
    >
      <div>
        <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase pb-4">
          {blok.title}
        </h3>
        {blok?.card.length > 0 ? (
          <NavCard blok={blok?.card[0]} />
        ) : (
          <ArticleCard blok={blok?.page} />
        )}
      </div>
      {blok?.linkText &&
        <div className="mt-6 text-sm font-medium">
          <Link
            href={absoluteUrl(blok?.link?.story?.url || "#")}
            onClick={() => close()}
            className="text-cto_primary_orange hover:text-indigo-500">

            {blok?.linkText}
            <span aria-hidden="true"> &rarr;</span>

          </Link>
        </div>
      }
    </div>
  );
};

export default NavigationSidePanel;
