import { connectSearchBox } from "react-instantsearch-dom";
import { SearchIcon } from '@heroicons/react/outline'

function CustomMobileSearchBox({ refine }) {
  return (
    <form action="/search" role="search" autoComplete="off">
      <div className="flex-1 flex justify-center lg:justify-end">
        <div className="w-full px-2 lg:px-6">
          <div className="relative focus-within:text-gray-400">
            <div 
              className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
              style={{ padding: '10px' }}
            >
              <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
            <input
              id="algolia_search"
              name="query"
              className="block w-full p-2 rounded-md leading-5 bg-white text-gray-900 placeholder-gray-500 focus:placeholder-gray-400 sm:text-sm"
              placeholder="Search"
              onChange={(e) => refine(e.currentTarget.value)}
              style={{ outlineWidth: 0, paddingLeft: '40px' }} // strange tailwindcss glitch... the classname 'outline-0' doesnt work, but the style 'outlineWidth: 0' does...
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default connectSearchBox(CustomMobileSearchBox);