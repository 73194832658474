
import { HeroIcon } from "../../lib/HeroIcon";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

const NavigationMobileSection = ({ panel, activePanelTitle, hideArrows }) => {
  return (
    <div>
      <a className="flex items-center rounded-lg hover:bg-gray-50">
        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12">
          <HeroIcon icon={panel.icon} color="text-gray-700" className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4 text-base font-medium text-gray-700 w-full">{panel.title}</div>
        {!hideArrows &&
          <div className="flex-shrink-0 flex items-center justify-center right-0 mr-5 h-10 w-10 rounded-md sm:h-12 sm:w-12">
            {activePanelTitle == panel.title
              ? (<ChevronUpIcon icon={panel.icon} color="text-gray-700" className="h-6 w-6" aria-hidden="true" />)
              : (<ChevronDownIcon icon={panel.icon} color="text-gray-700" className="h-6 w-6" aria-hidden="true" />)}
            
          </div>
        }
      </a>
    </div>
  )
}

export default NavigationMobileSection;