import { animate, stagger } from "motion";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";

export default function Animation() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    animate("#C-letter", { x: 60 }, { delay: 1, duration: 2 });
    animate("#C-letter", { opacity: 0 }, { delay: 1, duration: 2 });

    animate("#T-letter", { opacity: 0 }, { delay: 1, duration: 2 });

    animate("#O-group", { x: -60 }, { delay: 1, duration: 2 });
    animate("#O-fill", { opacity: 1 }, { delay: 1, duration: 2 });

    animate("#dot", { opacity: 1 }, { delay: 3 });
    animate("#dot", { transform: "scale(30)" }, { delay: 3, duration: 1 });
    // animate("#animation", { opacity: 0 }, { delay: 4, duration: 2 });
    animate(
      "#animation",
      { opacity: 0 },
      { delay: 4, duration: 2 }
    ).finished.then(() => {
      setVisible(false);
    });
  }, []);

  return !visible ? null : (
    <div id="animation">
      <div
        id="dot"
        className="absolute grid opacity-0 place-items-center h-screen w-screen top-0 left-0 bg-transparent z-20"
      >
        <Image src="/logo/Dot.svg" alt="" width={100} height={100} />
      </div>
      <div
        id="logo-animation"
        className="absolute grid place-items-center h-screen w-screen top-0 left-0 bg-cto_secondary_light_warm_grey"
      >
        <div className="flex relative">
          <div id="C-letter" className="-mr-10">
            <Image src="/logo/C.svg" alt="" width={100} height={100} />
          </div>
          <div id="T-letter">
            <Image src="/logo/T.svg" alt="" width={100} height={100} />
          </div>
          <div
            id="O-group"
            className="-ml-10 relative overflow-hidden bg-no-repeat"
          >
            <div
              id="O-letter"
              className="absolute top-0 right-0 bottom-0 left-0 z-10"
            >
              <Image src="/logo/O.svg" alt="" width={100} height={100} />
            </div>
            <div
              id="O-fill"
              className="opacity-0 relative overflow-hidden bg-no-repeat"
            >
              <Image src="/logo/O-fill.svg" alt="" width={100} height={100} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
