import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import CustomMobileSearchBox from "./CustomMobileSearchBox";
import CustomMobileHits from "./CustomMobileHits";
import React from 'react';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

export default function MobileSearch() {
  return (
    <>
      <InstantSearch 
        searchClient={searchClient} 
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}
      >
        <CustomMobileSearchBox />
        <CustomMobileHits />
      </InstantSearch>
    </>
  );
}