import Link from "next/link";
import { useEffect, useState } from "react";
import { connectStateResults } from "react-instantsearch-dom";

import { absoluteUrl } from "../_helper";
import { getSearchResultTag, getNavSearchResultFeedback } from "./searchHelper"

function CustomHits({ searchState, searchResults }) {
  const validQuery = searchState.query?.length >= 1;

  const [topHits, setTopHits] = useState([])
  useEffect(() => {
    setTopHits(searchResults?.hits.slice(0, 5)); //retrieve first 5 results...
  }, [searchResults])

  return (
    <div className="" style={{ width: '-webkit-fill-available' }}>
      {validQuery && (
        <div className="bg-white shadow overflow-hidden rounded-md mt-4">
          <ul role="list" className="divide-y divide-gray-200">
            {topHits?.map((hit) => (
              <li key={hit.id}>
                <Link href={absoluteUrl(hit.full_slug)} className="block hover:bg-gray-50">

                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <span className="text-sm font-medium text-cto_primary_purple">{hit.name}</span>
                      <div>
                        {getSearchResultTag(hit.full_slug)}
                      </div>
                    </div>
                  </div>

                </Link>
              </li>
            ))}
            {getNavSearchResultFeedback(searchResults?.hits.length)}
          </ul>
        </div>
      )}
    </div>
  );
}

export default connectStateResults(CustomHits);