/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import NavigationSidePanel from "./NavigationSidePanel";
import { HeroIcon } from "../../lib/HeroIcon";
import { absoluteUrl, PropertyExists } from "../../lib/_helper";
import { mergeClasses } from "../../lib/_helper";

const NavigationPanel = (panel) => {
  const standardMainTextColour = "text-white";
  const standardSelectedMainTextColour = "text-gray-400";

  const hoverMainTextColour = "text-white";
  const hoverSelectedMainTextColour = "text-gray-400";

  const MainTextColour = panel.isHoveringNavBar
    ? hoverMainTextColour
    : standardMainTextColour;
  const SelectedMainTextColour = panel.isHoveringNavBar
    ? hoverSelectedMainTextColour
    : standardSelectedMainTextColour;

  return (
    <Popover className="h-6">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={mergeClasses(
              open ? SelectedMainTextColour : MainTextColour,
              "hover:text-opacity-60 focus-visible:text-opacity-60",
              "group bg-transparent rounded-md inline-flex items-center text-base font-medium",
              "focus-visible:ring-0 focus-visible:outline-0 focus-visible:border-0"
            )}
          >
            <div className="flex justify-center items-center">
              <div className="mr-1.5 -mb-0.5 h-3.5 w-3.5">
                <svg
                  className={mergeClasses(
                    "fill-current",
                    panel.isActivePage ? "text-peachy-orange-800" : "hidden"
                  )}
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="50" />
                </svg>
              </div>
              <span>{panel.data.title}</span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              className="nav-bar-background hidden md:block absolute z-10 overflow-auto top-full inset-x-0 transform shadow-lg"
              style={{ height: "fit-content", maxHeight: "calc(100vh - 100px)" }}
            >
              <div className="relative max-w-7xl mx-auto grid grid-cols-2 lg:grid-cols-5">
                <nav className="px-4 py-8 bg-white col-span-3 sm:grid-cols-2 sm:gap-x-8 sm:py-8 sm:px-6 lg:px-8 xl:pr-12">
                  <div className="grid gap-y-10 overflow-auto">
                    <div className="col-span-2">
                      <Link
                        href={absoluteUrl(
                          panel?.data?.buttonUrl?.story?.url || "#"
                        )}
                        onClick={() => close()}>

                        <h3 className="text-lg font-medium tracking-wide text-cto_primary_orange uppercase underline">
                          {panel.data.linkHeader}
                        </h3>

                      </Link>
                      <div role="list" className="grid grid-cols-2 mt-5">
                        {panel.data.links.map((link, index) => (
                          link.hideFromNavigation ||
                          <div key={index}>
                            <Link
                              href={absoluteUrl(link?.url?.story?.url || "#")}
                              className="m-3 p-3 flex rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                              onClick={() => close()}>

                              {PropertyExists(link?.icon) && (
                                <span
                                  className="flex-shrink-0 h-6 w-6"
                                  aria-hidden="true"
                                >
                                  <HeroIcon
                                    icon={link.icon}
                                    color="text-cto_secondary_navy_blue"
                                    size={6}
                                    outline
                                  />
                                </span>
                              )}
                              <div className="flex-col">
                                <div className="ml-4 text-gray-700">
                                  {link.linkText || "#"}
                                </div>
                                <div className="ml-4 text-gray-400">
                                  {link.linkDescription || "#"}
                                </div>
                              </div>

                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Link
                      href={absoluteUrl(
                        panel?.data?.buttonUrl?.story?.url || "#"
                      )}
                      className="ml-8 py-4 max-h-14 col-span-2 inline-flex items-center justify-center border border-transparent rounded-full shadow-sm text-base font-normal text-white bg-cto_primary_orange hover:bg-cto_primary_purple"
                      onClick={() => close()}>

                      {panel.data.buttonText || "#"}

                    </Link>
                  </div>
                </nav>
                {panel?.data?.sidePanels.map((sidePanel, index) => (
                  <NavigationSidePanel blok={sidePanel} key={index} close={close} />
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default NavigationPanel;
