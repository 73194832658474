import { connectSearchBox } from "react-instantsearch-dom";

function CustomSearchBox({ refine }) {
  return (
    <div className="bg-white block w-full p-4">
      <form action="/search" role="search" autoComplete="off">
        <input
          id="algolia_search"
          name="query"
          className="leading-5 sm:text-sm placeholder-gray-400 text-gray-600"
          placeholder="Search"
          onChange={(e) => refine(e.currentTarget.value)}
          style={{ outlineWidth: 0 }} // strange tailwindcss glitch... the classname 'outline-0' doesnt work, but the style 'outlineWidth: 0' does...
        />
      </form>
    </div>
  );
}

export default connectSearchBox(CustomSearchBox);