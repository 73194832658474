import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { absoluteUrl } from "../lib/_helper";
import { mergeClasses } from "../lib/_helper";
import { getSlugDisplayName } from "../lib/Search/searchHelper";

function getConstructedHref(index, array) {
  const href = "/" + array.slice(0, index + 1).join("/");
  return href;
}

export default function Breadcrumbs({ splittedSlug, backgroundColour }) {
  if (!splittedSlug) {
    return null;
  }

  let pages = splittedSlug.map((ss, i, array) => {
    return { key: ss, href: getConstructedHref(i, array), current: false };
  });
  return (
    <div
      className={mergeClasses(
        backgroundColour
          ? "bg-" + backgroundColour
          : "bg-cto_secondary_navy_blue"
      )}
    >
      <div className="py-10 lg:pt-32 lg:pb-0 px-6 lg:px-8 max-w-7xl mx-auto overflow-x-auto">
        <nav className="flex relative z-10" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link href={absoluteUrl("/")} className="text-white hover:text-gray-400">

                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>

                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.key}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    href={absoluteUrl(page.href)}
                    className="ml-4 text-sm font-medium text-white hover:text-gray-500 whitespace-nowrap"
                    aria-current={page.current ? "page" : undefined}>

                    {getSlugDisplayName(page.key)}

                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
