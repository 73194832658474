import { ChevronRightIcon } from "@heroicons/react/solid";

const displayNames = {
  // Add 'key' to 'display name' translations here...
  manda: "M&A",
};

export function getSlugDisplayName(key) {
  let displayName = displayNames[key];
  if (!displayName && !!key) {
    displayName = key;
    displayName = UpperCaseFirstLetterOfEachWord(displayName);
  }
  return displayName ?? "";
}

function UpperCaseFirstLetterOfEachWord(str) { 
  const words = str.split("-");

  for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
}

export function getSearchFeedback(length) {
  if (length == null) {
    return null;
  } 

  return (
    <li key='end'>
      <a className="block">
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            {getBottomText(length)}
          </div>
        </div>
      </a>
    </li>
  );
}

function getBottomText(length) {
  if (length == 0) {
    return (<p className="text-sm font-medium text-gray-600 truncate">Aw snap! No search results were found...</p>);
  }
  else if (length == 1) {
    return (<p className="text-sm font-medium text-gray-600 truncate">Showing only result...</p>);
  }
  else {
    return (<p className="text-sm font-medium text-gray-600 truncate">Showing all {length} results...</p>)
  }
}

export function getSearchResultTag(full_slug) {
  return (
    <div className="ml-2 flex px-4 bg-rich-purple-100 rounded-full">
      <p className="inline-flex text-xs leading-5 font-semibold text-gray-800">
        {full_slug.split("/").slice(0,-1).map((partialSlug, i) => (
          <li key={partialSlug}>
            <div className="flex items-center">
              {(i != 0 && partialSlug != "") && <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />}
              <span
                className="ml-x text-sm font-medium text-gray-700 hover:text-gray-600 whitespace-nowrap"
              >
                {getSlugDisplayName(partialSlug)}
              </span>
            </div>
          </li>
        ))}
      </p>
    </div>);
}

export function getNavSearchResultFeedback(numHits) {
  if (numHits == null) {
    return null;
  }

  const resultsString = numHits == 1 ? "1 result" : ("all " + numHits + " results")
  return (
    <li key='end'>
      <span className="block">
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            {numHits > 0
              ? <p className="text-sm font-medium text-gray-600 truncate">Press &apos;Enter&apos; key to show {resultsString}...</p>
              : <p className="text-sm font-medium text-gray-600 truncate">Aw snap! No search results were found...</p>
            }
          </div>
        </div>
      </span>
    </li>
  );
}