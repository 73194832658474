import Link from 'next/link'
import { useEffect, useState, useCallback } from "react";
import { SearchIcon } from "@heroicons/react/outline";

import Search from "../../../lib/Search/Search";
import { absoluteUrl, mergeClasses } from '../../../lib/_helper';

function SearchAndContactUs() {
  const [isShowingSearchBar, setIsShowingSearchBar] = useState(false);

  const focusOnSearchBox = () => {
    document.getElementById("algolia_search")?.focus()
  }

  // update the state of the visibility of the search-bar, and set focus if is is being newly shown
  const updateIsShowingSearchBar = useCallback((newValue) => {
      if (newValue != undefined) {
        const oldValue = isShowingSearchBar;
        setIsShowingSearchBar(newValue);
        if (!oldValue && newValue) {
          setTimeout(() => focusOnSearchBox(), 100);
        }
      }
    }, [isShowingSearchBar]
  );

  // Toggle the visiblity of the search bar when 'enter' is pressed on the search icon...
  const enterFunction = useCallback(
    (event) => {
      if (event) {
        if (event.key === "Enter") {
          updateIsShowingSearchBar(!isShowingSearchBar);
        }
      }
    },
    [isShowingSearchBar, updateIsShowingSearchBar]
  );

  // Add event listeners to the search-icon
  useEffect(() => {
    document
      .getElementById("search-icon")
      .addEventListener("keydown", enterFunction);

    return () => {
      document
        .getElementById("search-icon")
        ?.removeEventListener("keydown", enterFunction);
    };
  }, [enterFunction]);

  return (
    <div className="flex justify-end items-center">
      <div
        className="rounded-full h-full items-center mr-3 hidden md:flex w-11 justify-center cursor-pointer bg-rich-purple-700"
        onClick={() => updateIsShowingSearchBar(!isShowingSearchBar)}
      >
        <label htmlFor="search" className="sr-only">
          Search
        </label>

        <div
          id="search-icon"
          className={mergeClasses(
            "px-5 focus-visible:text-opacity-50 focus-visible:outline-0 focus-visible:opacity-70 focus:outline-0 hover:opacity-50",
          )}
          onClick={() => updateIsShowingSearchBar(!isShowingSearchBar)}
          tabIndex={0}
        >
          <SearchIcon className="h-5 w-5 text-white" />
        </div>
        <div>
          {isShowingSearchBar && (
            <div 
              className="absolute right-0 z-1 w-80 bg-white rounded-md shadow overflow-hidden my-8 mx-12 divide-y" style={{ marginLeft: '-47px' }}
              onClick={() => focusOnSearchBox()}
            >
              <Search clearSearch={() => setIsShowingSearchBar(false)} />
            </div>
          )}
        </div>
      </div>
      <div>
        <Link
          href={absoluteUrl("/contact")}
          className="inline-flex items-center justify-center bg-cto_primary_orange hover:bg-cto_secondary_light_warm_grey hover:text-cto_primary_purple whitespace-nowrap px-8 py-2 border border-transparent rounded-full shadow-sm text-base font-normal text-white focus-visible:opacity-80 focus-visible:outline-0">
          
            Contact us
          
        </Link>
      </div>
    </div>
  );
}

export default SearchAndContactUs;


