/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import NavigationSidePanel from "./NavigationSidePanel";
import { HeroIcon } from "../../lib/HeroIcon";
import { absoluteUrl, PropertyExists } from "../../lib/_helper";
import { mergeClasses } from "../../lib/_helper";

const NavigationDirectLink = (panel) => {
  const standardMainTextColour = "text-white";
  const standardSelectedMainTextColour = "text-gray-400";

  const hoverMainTextColour = "text-white";
  const hoverSelectedMainTextColour = "text-gray-400";

  const MainTextColour = panel.isHoveringNavBar
    ? hoverMainTextColour
    : standardMainTextColour;
  const SelectedMainTextColour = panel.isHoveringNavBar
    ? hoverSelectedMainTextColour
    : standardSelectedMainTextColour;

  return (
    <Popover className="h-6">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={mergeClasses(
              open ? SelectedMainTextColour : MainTextColour,
              "hover:text-opacity-60 focus-visible:text-opacity-60",
              "group bg-transparent rounded-md inline-flex items-center text-base font-medium",
              "focus-visible:ring-0 focus-visible:outline-0 focus-visible:border-0"
            )}
          >
            <div className="flex justify-center items-center">
              <div className="mr-1.5 -mb-0.5 h-3.5 w-3.5">
                <svg
                  className={mergeClasses(
                    "fill-current",
                    panel.isActivePage ? "text-peachy-orange-800" : "hidden"
                  )}
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="50" />
                </svg>
              </div>
              <Link href={absoluteUrl(panel.data.buttonUrl.cached_url)}>

                <span>{panel.data.title}</span>

              </Link>
            </div>
          </Popover.Button>
        </>
      )}
    </Popover>
  );
};

export default NavigationDirectLink;
